<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a-tooltip placement="top">
          <template slot="title"><span>Add Fuel Purpose Type</span></template>
          <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-fuel-purpose-type-modal @click="$bvModal.show('add-update-fuel-purpose-type-modal'), resetForm()">
            <i class="fa fa-plus"/> Add Fuel Purpose Type
          </a>
        </a-tooltip>
        <table class="table table-sm table-bordered text-center" id="fuel_purpose_type_list">
          <thead>
          <tr>
            <th>#</th>
            <th>Fuel Purpose Type</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fuel_purpose_type, index) in fuel_purpose_types" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ fuel_purpose_type.fuel_purpose_type_desc }}</td>
            <td>
              <a class="btn btn-sm btn-primary mr-2" href="javascript: void(0);" @click.prevent="edit(fuel_purpose_type.id), $bvModal.show('add-update-fuel-purpose-type-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
              <a class="btn btn-sm btn-danger mr-2" href="javascript: void(0);" @click.prevent="onDelete(fuel_purpose_type.id)"><i class="fa fa-trash mr-1"></i>Delete</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="fuel_purpose_types.length === 0 && !flag">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-fuel-purpose-type
      :fuel_purpose_type="fuel_purpose_type"
      :btnEdit="btnEdit"
      @getFuelPurposeTypes="getFuelPurposeTypes"
      @resetForm="resetForm">
    </add-update-fuel-purpose-type>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateFuelPurposeType from '@/views/fuelPurposeType/modal/addUpdateFuelPurposeType'
import $ from 'jquery'

export default {
  name: 'List',
  components: {
    Empty,
    addUpdateFuelPurposeType,
  },
  data() {
    return {
      fuel_purpose_types: [],
      fuel_purpose_type: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getFuelPurposeTypes()
  },
  methods: {
    getFuelPurposeTypes() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/fuel-purpose-types')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.fuel_purpose_types = response.data.fuel_purpose_types
          setTimeout(function () {
            $('#fuel_purpose_type_list').DataTable(
              {
                bRetrieve: true,
                pagingType: 'full_numbers',
                pageLength: 10,
                processing: true,
                dom: 'Blfrtip',
                buttons: ['copy', 'csv', 'print'],
              },
            )
          }, 3000,
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.fuel_purpose_type = {}
      this.btnEdit = true
      apiClient.get('api/fuel-purpose-types/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.fuel_purpose_type = response.data.fuel_purpose_type
        }
      })
    },
    onDelete(Id) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/fuel-purpose-types/' + Id).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              $('#fuel_purpose_type_list').DataTable().destroy()
              this.getFuelPurposeTypes()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.fuel_purpose_type = {}
    },
  },
}
</script>

<style scoped>

</style>
